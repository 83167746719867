import React from 'react'
import Arrow from '../images/Arrow.svg'

const Cooperation = () => {
    return (
        <>
            <h1>Jak bude probíhat naše spolupráce<hr /></h1>
            <div className='cooperation'>
                <div className='coop-activity'>
                    <div style={{ border: '1px solid red', padding: '10px', borderRadius: '50%', width: '98px' }}>
                        <div className='purple-circle coop-circle' style={{ backgroundColor: 'red' }}>
                            <h6>A</h6>
                        </div>
                    </div>
                    <h2>Analýza</h2>
                    <p>Podrobněji vysvětlující text této části spolupráce</p>
                </div>
                <img className='coop-arrow' src={Arrow} alt='arrow' height={25}></img>
                <div className='coop-activity'>
                    <div style={{ border: '1px solid lightblue', padding: '10px', borderRadius: '50%', width: '98px' }}>
                        <div className='purple-circle coop-circle' style={{ backgroundColor: 'lightblue' }}>
                            <h6>P</h6>
                        </div>
                    </div>
                    <h2>Poradenství</h2>
                    <p>Podrobněji vysvětlující text této části spolupráce</p>
                </div>
                <img className='coop-arrow' src={Arrow} alt='arrow' height={25}></img>
                <div className='coop-activity'>
                    <div style={{ border: '1px solid lightgreen', padding: '10px', borderRadius: '50%', width: '98px' }}>
                        <div className='purple-circle coop-circle' style={{ backgroundColor: 'lightgreen' }}>
                            <h6>R</h6>
                        </div>
                    </div>
                    <h2>Realizace</h2>
                    <p>Podrobněji vysvětlující text této části spolupráce</p>
                </div>
                <img className='coop-arrow' src={Arrow} alt='arrow' height={25}></img>
                <div className='coop-activity'>
                    <div style={{ border: '1px solid orange', padding: '10px', borderRadius: '50%', width: '98px' }}>
                        <div className='purple-circle coop-circle' style={{ backgroundColor: 'orange' }}>
                            <h6>S</h6>
                        </div>
                    </div>
                    <h2>Servis</h2>
                    <p>Podrobněji vysvětlující text této části spolupráce</p>
                </div>
            </div>
        </>
    )
}

export default Cooperation