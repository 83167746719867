import React from 'react'

const Footer = () => {
    return (
        <footer className='footer'>
            <p>Web by: Vojtěch Procházka</p>
            <p>Powered by React</p>
            <p>All Rights Reserved</p>
        </footer>
    )
}

export default Footer