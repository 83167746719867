import React from 'react'
import './Testimonials.css'

const Testimonials = () => {
    return (
        <div className='testimonials'>
            <h1>Co o mně píší klienti<hr /></h1>
            <p className='testimonials-under-heading'>V této sekci najdete reference od některých našich spokojených klientů. Přečtěte si, co nám sdělili o svých zkušenostech ze spolupráce s námi. Věříme, že jejich zpětná vazba vám pomůže lépe pochopit náš závazek poskytovat výjimečné služby a pomáhat klientům dosáhnout jejich finančních snů.</p>
            <div className='reviews-container'>
                <div className='review side-review'>
                    <div className='review-photo alex'>
                    </div>
                    <h3>Alex</h3>
                    <h4>Král New Yorku</h4>
                    <p className='review-text'>
                        "S Pavlínou spolupracuji již několik let a vždy mě ohromí jejich profesionalita a znalosti. Pomohli mi s rozumnými finančními rozhodnutími, která zlepšila moji finanční situaci."
                    </p>
                </div>
                <div className='review middle-review'>
                    <div className='review-photo vojta'>
                    </div>
                    <h3>Vojtěch Procházka</h3>
                    <h4>Hlavní nabíječ</h4>
                    <p className='review-text'>
                        "Pavlína pro mě byla skutečnou finanční poradkyní. Věnovala mi čas, aby pochopila mé finanční cíle a sestavila personalizovaný plán, který mi pomůže je dosáhnout. Jsem si jistá, že jsem na správné cestě k zajištěné finanční budoucnosti."
                    </p>
                </div>
                <div className='review side-review'>
                    <div className='review-photo mickey'>
                    </div>
                    <h3>Mickey Mouse</h3>
                    <h4>Fiktivní postava</h4>
                    <p className='review-text'>
                        "Vřele doporučuji Pavlínu každému, kdo hledá finančního poradce. Je poctivá, důvěryhodná a skutečně se zajímá o nejlepší zájmy svých klientů."
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Testimonials