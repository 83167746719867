import React from 'react'
import EdoLogo from '../images/edo_logo_white.png'

const Header = () => {
    return (
        <header className="App-header ">
            <div className="name">
                <img src={EdoLogo} alt="eDO_logo" height={40}></img>
                <div className="vertical-line"></div>
                Pavlína Fuchsová <br />
                Finanční poradkyně
            </div>
            <div className='App-header-menu'>
                <h4>O mně</h4>
                <h4>Služby</h4>
                <h4>eDO</h4>
                <h4>Partneři</h4>
                <h4>Kontakt</h4>
            </div>
            <button className='call-button'>Zavolej mi</button>
        </header>
    )
}

export default Header