import * as React from 'react';
import AboutMe from './components/AboutMe';
import Services from './components/Services';
import Cooperation from './components/Cooperation';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';
import Header from './components/Header';
import './App.css';
import Testimonials from './components/Testimonials';
import Actualities from './components/Actualities';

function App() {
  return (
    <div className="App">
      <Header />
      <AboutMe />
      <Services />
      <Cooperation />
      <Testimonials />
      <Actualities />
      <ContactMe />
      <Footer />
    </div>
  );
}

export default App;
