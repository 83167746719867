import React from 'react'
import PajaIntro from '../images/Paja_intro.jpg'
import PajaMoskva from '../images/Paja_Moskva.png';

const AboutMe = () => {
    return (
        <div className='about-me'>
            <div className='about-me-text'>
                <h1>Vydělám vám hromady</h1><hr />
                <h3>Jsem Pavlínka a mám dvě krásná prsa velikosti 5.
                    Mám ráda dobrou kuchyni, rychlá auta a horkou vanu po těžkém dni.
                    S radostí ti poradím kam mi můžeš strčit své peníze</h3>
                <button className='purple-button'>Chci poradit</button>
            </div>
            <img src={PajaIntro} alt='paja_moskva' />
        </div>
    )
}

export default AboutMe