import React from 'react'
import { MdOutlinePlace } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import map from '../images/mapa.jpg'

const ContactMe = () => {
    return (
        <>
            <h1>Přijďtě  / Napište<hr /></h1>
            <div className='contact'>
                <div className='contact-info'>
                    <h3>Bc. Pavlína Fuchsová</h3>
                    <p><MdOutlinePhone className='color-pink' style={{ marginRight: "0.5rem" }} />+420 774 239 802</p>
                    <p><CiMail className='color-pink' style={{ marginRight: "0.5rem" }} /> pavlina.fuchsova@gmail.com </p>
                    <p><MdOutlinePlace className='color-pink' style={{ marginRight: "0.5rem" }} /> S. K. Neumanna 1220, 530 02 Pardubice V-Zelené Předměstí</p>
                    <img src={map} alt='map_image' width='100%' />
                </div>
                <form className='form'>
                    <div className='form-names'>
                        <div className='form-name'>
                            <label for='fname'>Jméno</label>
                            <input
                                className='input-name'
                                id='fname'
                                type="text" />
                        </div>
                        <div className='form-name'>
                            <label for='fname'>Příjmení</label>
                            <input
                                className='input-name'
                                id='lname'
                                type="text" />
                        </div>
                    </div>
                    <label for='fname'>Email</label>
                    <input
                        type="text" />
                    <label for='fname'>Zpráva</label>
                    <textarea
                        rows={5}
                        type="text" />
                    <p className='policy'>Odesláním automaticky souhlasíte se zpracováním údajů a podmínkami zásad ochrany osobních údajů.</p>
                    <button className='purple-button'>
                        Odeslat
                    </button>
                </form>
            </div>
        </>
    )
}

export default ContactMe