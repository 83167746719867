import React from 'react';
import './Actualities.css';
import Money from '../images/money.jpg';

const Actualities = () => {
    return (
        <div>
            <h1>Aktuality ve světě financí<hr /></h1>
            <div className='actualities-container'>
                <div className='actuality'>
                    <img src={Money} alt='actuality_image' width='100%' />
                    <h6>23.02.2022</h6>
                    <h4>Investice a jak na ně 2. díl podcastu</h4>
                    <p>Investice= horizont/výnos/rizika. To je potřeba vždy promyšlené. Investice, jak fungují […]</p>
                    <button>Read more</button>
                </div>
                <div className='actuality'>
                    <img src={Money} alt='actuality_image' width='100%' />
                    <h6>23.02.2022</h6>
                    <h4>Investice a jak na ně 2. díl podcastu</h4>
                    <p>Investice= horizont/výnos/rizika. To je potřeba vždy promyšlené. Investice, jak fungují […]</p>
                    <button>Read more</button>
                </div>
                <div className='actuality'>
                    <img src={Money} alt='actuality_image' width='100%' />
                    <h6>23.02.2022</h6>
                    <h4>Investice a jak na ně 2. díl podcastu</h4>
                    <p>Investice= horizont/výnos/rizika. To je potřeba vždy promyšlené. Investice, jak fungují […]</p>
                    <button>Read more</button>
                </div>
            </div>
        </div>
    )
}

export default Actualities