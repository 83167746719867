import React from 'react'
import InvestmentsIcon from '../images/investmentsIcon.svg'
import MortgagesIcon from '../images/mortgagesIcon.svg'
import InsurancesIcon from '../images/insurancesIcon.svg'
import LoansIcon from '../images/loansIcon.svg'
import PensionIcon from '../images/pensionIcon.svg'

const Services = () => {
    return (
        <div className='services'>
            <h1>Služby<hr /></h1>
            <h3>Služby které vám mohu nabídnout. Nabízím Vám služby, které vás uspokojí</h3>
            <div className='services-container'>
                <div className='service-card'>
                    <div className='purple-circle' >
                        <img src={InvestmentsIcon} alt='pension_icon' />
                    </div>
                    <h2>INVESTICE</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse enim metus, viverra ac nunc ut, cursus venenatis tellus. Nunc tristique enim in neque lobortis, maximus finibus leo condimentum. Nam gravida.</p>
                </div>
                <div className='service-card'>
                    <div className='purple-circle' >
                        <img src={MortgagesIcon} alt='pension_icon' />
                    </div>
                    <h2>HYPOTÉKY</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse enim metus, viverra ac nunc ut, cursus venenatis tellus. Nunc tristique enim in neque lobortis, maximus finibus leo condimentum. Nam gravida.</p>
                </div>
                <div className='service-card'>
                    <div className='purple-circle' >
                        <img src={InsurancesIcon} alt='pension_icon' />
                    </div>
                    <h2>POJIŠTĚNÍ</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse enim metus, viverra ac nunc ut, cursus venenatis tellus. Nunc tristique enim in neque lobortis, maximus finibus leo condimentum. Nam gravida.</p>
                </div>
                <div className='service-card'>
                    <div className='purple-circle' >
                        <img src={LoansIcon} alt='pension_icon' />
                    </div>
                    <h2>ÚVĚRY</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse enim metus, viverra ac nunc ut, cursus venenatis tellus. Nunc tristique enim in neque lobortis, maximus finibus leo condimentum. Nam gravida.</p>
                </div>
                <div className='service-card'>
                    <div className='purple-circle' >
                        <img src={PensionIcon} alt='pension_icon' />
                    </div>
                    <h2>PENZE</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse enim metus, viverra ac nunc ut, cursus venenatis tellus. Nunc tristique enim in neque lobortis, maximus finibus leo condimentum. Nam gravida.</p>
                </div>
            </div>
        </div>
    )
}

export default Services